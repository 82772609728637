import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import { graphql } from 'gatsby';
import Callout from '../../components/Callout';
import ComponentApi from '../../components/ComponentApi';
import ReactPlayground from '../../components/ReactPlayground';
import Collapse from '../../examples/Collapse';
import Fade from '../../examples/Fade';
export const query = graphql`
  query TransitionQuery {
    Fade: componentMetadata(displayName: { eq: "Fade" }) {
      ...ComponentApi_metadata
    }
    Collapse: componentMetadata(displayName: { eq: "Collapse" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "transitions"
    }}>{`Transitions`}</h1>
    <p>{`Bootstrap includes a few general use CSS transitions that can be applied
to a number of components. React Bootstrap, bundles them up into a few composable
`}<inlineCode parentName="p">{`<Transition>`}</inlineCode>{` components from `}<a parentName="p" {...{
        "href": "https://github.com/reactjs/react-transition-group"
      }}>{`react-transition-group`}</a>{`, a commonly used animation wrapper for React.`}</p>
    <p>{`Encapsulating animations into components has the added benefit of making them  more broadly useful,
as well as portable for using in other libraries.  All React-bootstrap components that can be animated, support pluggable
`}<inlineCode parentName="p">{`<Transition>`}</inlineCode>{` components.`}</p>
    <h2 {...{
      "id": "collapse"
    }}>{`Collapse`}</h2>
    <p>{`Add a collapse toggle animation to an element or component.`}</p>
    <Callout title="Smooth animations" mdxType="Callout">
  If you're noticing choppy animations, and the component that's being
  collapsed has non-zero margin or padding, try wrapping the contents of
  your <code>{'<Collapse>'}</code> inside a node with no margin or
  padding, like the <code>{'<div>'}</code> in the example below. This will
  allow the height to be computed properly, so the animation can proceed
  smoothly.
    </Callout>
    <ReactPlayground codeText={Collapse} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "fade"
    }}>{`Fade`}</h2>
    <p>{`Add a fade animation to a child element or component.`}</p>
    <ReactPlayground codeText={Fade} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ComponentApi metadata={props.data.Collapse} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.Fade} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      